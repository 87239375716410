import { Carousel, Row, Col } from 'antd';
import React, { useEffect } from 'react';
import ButtonContainer from '../components/ButtonContainer';
import './index.css'

export default function Experience({ history }) {

    useEffect(() => {
        window.scrollTo(0, 0);;
    }, []);
    return (
        <Row type="flex" >


            <Col span={24} >
                <img src={require("../assets/images/banner.png")} className="header-banner" alt='header-banner' />
                <div className="banner-content">
                    DENEYİM ALANLARI
                </div>
            </Col>
            <Col span={24} className="experience-content">
            <img src={require("../assets/images/sol-ust.svg").default} className="sol-ust" alt='sol-ust' />
                <Row gutter={16} type="flex experience-content-row  ">
                    <Col xl={12} md={12} sm={24} xs={24}  className="padding4">
                        <Row>
                            <Col xl={24} md={24} sm={24} xs={24} className="padding2">
                                <img src={require("../assets/images/deneyimpic1.svg").default} className="deneyimpic1" alt='deneyimpic1' />
                            </Col>
                            <Col xl={24} md={24} sm={24} xs={24} className="padding2" >
                                <Row>

                                    <Col xl={24} md={24} sm={24} xs={24} className="experience-desc paddingL2">
                                        <h4> SÜRAT TEKNESİ </h4>
                                        <h5> Fuaye alanında sizleri karşılayacak sürat teknesi alanımızda VR gözlük ile sizleri okyanusta sürat teknesi deneyimi yaşamaya davet ediyoruz.
                                            <br></br>
                                            <br></br>
                                            <strong> Yüksek skor için bitiş noktasından en hızlı geçmeyi de unutmayın.</strong>
                                        </h5>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col  xl={12} md={12} sm={24} xs={24} className="padding4" >
                        <Row>
                            <Col xl={24} md={24} sm={24} xs={24} className="padding2">
                                <img src={require("../assets/images/deneyimpic2.svg").default} className="deneyimpic2 " alt='deneyimpic2' />
                            </Col>
                            <Col xl={24} md={24} sm={24} xs={24} className="padding2" >
                                <Row>

                                    <Col xl={24} md={24} sm={24} xs={24} className="experience-desc paddingL2">
                                        <h4> BEYİN GÜCÜ OYUNU </h4>
                                        <h5> Konsantrasyonuzu test etmeye var mısınız?  Amaç alanda yer alan beyin gücü aparatımız ile gemiyi rakibinizden önce karşı alana götürmektir.
                                            <br></br>
                                            <br></br>
                                            <strong> Odaklanmak benim işim diyen tüm misafirlerimizi fuaye alanına bekliyoruz.</strong>
                                        </h5>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>


                <img src={require("../assets/images/sol-alt.svg").default} className="sol-alt" alt='sol-alt' />
                <img src={require("../assets/images/sag-ust.svg").default} className="sag-ust" alt='sol-alt' />
                <img src={require("../assets/images/sağ-alt.svg").default} className="sag-alt" alt='sag-alt' />
            </Col>

            <Col span={24} className='action-container'>
                {/* <ButtonContainer history={history}>

                </ButtonContainer> */}
            </Col>


        </Row>
    );
}