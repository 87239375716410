
export const Schedular1 = [
    {
        "id": 1,
        "time": "09:30 – 11:00",
        "header": "ANMA ve SAYGI TÖRENLERİ",
        "description": "Kabotaj Bayramı Kutlama Faaliyetleri"
    },
    {
        "id": 2,
        "time": "14:30 – 16:00",
        "header": "AÇILIŞ MERASİMİ",
        "persons": [{
            name: "Recep Tayyip ERDOĞAN",
            title: "Türkiye Cumhurbaşkanı*"
        },
        {
            name: "Adil Karaismailoğlu",
            title: "T.C. Ulaştırma ve Altyapı Bakanı"
        }
        ]

    },
    {
        "id": 3,
        "time": "16:00- 16:30",
        "header": "ARA",
    },
    {
        "id": 4,
        "time": "16:30 – 17:00",
        "header": "KEYNOTE",
        "header_color": "red",
        "persons": [{
            name: "Mark SZAKONYI",
            title: "IHS Markit Editörü"
        }
        ]
    }
    ,
    {
        "id": 5,
        "time": "17:00 – 18:30",
        "header": "TÜRK DENİZCİLİK SEKTÖRÜ VİZYONU: TÜRK FİLOSUNUN GELİŞTİRİLMESİ",
        "description": "Dünya taşımacılığının 2010-2020 yıllarındaki yüzdelik dağılımında 2020 yılında denizyolunun payı %90 olarak gerçekleşmiştir. Dış ticaret taşımacılığı ihracat yüklemesinde en fazla taşınan kargo sıralamasında, %38 ile konteyner ve dökme katı yük, %13 ile genel kargo, %8 ile sıvı dökme yük ve %4 araç olarak gerçekleşmiştir. Bugün 80 trilyon olan küresel ticaretin 2100 yılında ikiye katlanması beklenmektedir. Milyarlarca metreküplük mal büyüklüğünün taşımacılığı göz önünde bulundurulduğunda sektörel gelişimi desteklemek için yeni adımlar atılması gerekmektedir.",
        "titles": ["Küresel Ticaret Hacmindeki Gelişmeler",
            "Türkiye Deniz Taşımacılığı Hacmindeki Gelişmeler",
            "Deniz Ticaretindeki Teşvikler",
            "Dünya Deniz Taşımacılığı ve Dünya Filosu",
            "Türk Filo Hedefleri",
            "Türk İç Su Yolları ve Deniz Ticaretinde Kullanan Politikalar"],
        "persons": [{
            name: "Dr. Özkan POYRAZ",
            title: "Emekli Müsteşar",
            moderator:true
        },
        {
            name: "Durmuş ÜNÜVAR",
            title: "Kıyı Emniyeti Genel Müdürü"
        },
        {
            name: "Kâmil ÖZKAN",
            title: "Kurumlar Vergisi Daire Başkanı"
        },
        {
            name: "Yavuz ALBAYRAK",
            title: "BDDK Uygulama İl Daire Başkanı"
        },
        {
            name: "Ümit Recep UĞUR",
            title: "Denizbank Genel Müdür Yardımcısı"
        },
        {
            name: "S.Zeki ÇAKIR",
            title: "İMEAK DTO Meclis Başkanı"
        },
        {
            name: "Mehmet S. GÜNGEN",
            title: "Güngen Denizcilik Yönetim Kurulu Başkanı"
        }
        ]

    },
    {
        "id": 6,
        "time": "19:00",
        "header": "ÖDÜL TÖRENİ: DENİZCİLİK ÖDÜLLERİ",
    }
]