
import './App.css';
import {
  BrowserRouter,
} from "react-router-dom";
import Layout from "../src/layout/layout";
import { Helmet, HelmetProvider } from "react-helmet-async";
import 'antd/dist/antd.css';
function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title> Türkiye Denizcilik Zirvesi - 1-2 Temmuz 2022, Tersane İstanbul</title>
        <meta
          name="description"
          content="Türkiye’nin denizcilik vizyonunu tüm dünyaya aktaracağı, dünya denizcilik trendlerinin ve kalkınmadaki stratejik rolünün işleneceği konseptlerden oluşacak zirvedir.Ulaştırma ve Altyapı Bakanlığımızın vizyonu doğrultusunda sektör paydaşlarının karşılıklı bilgi alışverişinin ve ilişki ağlarının geliştirilmesine katkı sağlamak üzere bu yıl ikinci defa düzenlenecek olan zirvemizde, yerli ve yabancı katılımcıların tek bir çatı altında; oturumlar, deneyimsel uygulamalar, zenginleştirilmiş içerik ve metodolojilerle bir araya getirilmesi hedeflenmektedir." />;
      </Helmet>
      <BrowserRouter >
        <Layout />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
