

import { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  Route,
  Routes
} from "react-router-dom";

import Main from "../pages/main";
import Aboutus from "../pages/aboutus";
import Experience from "../pages/experience";
import Speakers from "../pages/speakers";
import Program from "../pages/program";
import Contact from "../pages/contact";

import { Layout, Row, Col, Space, Dropdown, Menu } from 'antd';
import './index.css'
import { MenuOutlined } from "@ant-design/icons";
const { Header, Footer, Sider, Content } = Layout;

export default function MainLayout() {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");
  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);
  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <Link to="/aboutus" >
              <h3 className="menu-item-hamburger">
                Hakkında
              </h3>
            </Link>
          ),
        },
        {
          key: '2',
          label: (
            <Link to="/program"  >
              <h3 className="menu-item-hamburger">
                Program
              </h3>
            </Link>
          ),
        },
        {
          key: '3',
          label: (
            <Link to="/speakers"  >
              <h3 className="menu-item-hamburger">
                Konuşmacılar
              </h3>
            </Link>
          ),
        },
        {
          key: '4',
          label: (<Link to="/experience">
            <h3 className="menu-item-hamburger">
              Deneyim
            </h3>
          </Link>)
        },
        {
          key: '5',
          label: (<Link to="/contact">
            <h3 className="menu-item-hamburger">
              İletişim
            </h3>
          </Link>)
        },
      ]}
    />
  );
  return (
    <div

    >
      <Layout className='container'>
        <Header style={{ position: 'fixed', zIndex: 110, width: '100%' }}>
          <Row gutter={16} type="flex" style={{ height: "100%" }}>
            <Col md={5} xl={5} sm={0} xs={0} className="padding2 alignL">
              <Link to="/">
                <img src={require("../assets/images/logo.svg").default} className="main-logo" alt='main-logo' />
              </Link>

            </Col>
            <Col md={0} xl={0} sm={12} xs={12} className="padding2 alignL">
              <Link to="/">
                <img src={require("../assets/images/logo-ikili.svg").default} className="main-logo-ikili" alt='main-logo-ikili' />
              </Link>
            </Col>
            <Col md={14} xl={14} sm={0} xs={0} >
              <Row gutter={16} type="flex" style={{ height: "100%" }}>

                <Col span={5} className="menu-item-col">
                  <Link to="/aboutus" >
                    <h3 className={location.pathname == "/aboutus" ? "menu-item active" : "menu-item"}>
                      Hakkında
                    </h3>
                  </Link>
                </Col>
                <Col span={5} className="menu-item-col">
                  <Link to="/program"  >
                    <h3 className={location.pathname == "/program" ? "menu-item active" : "menu-item"}>
                      Program
                    </h3>
                  </Link>     </Col>
                <Col span={6} className="menu-item-col">
                  <Link to="/speakers"  >
                    <h3 className={location.pathname == "/speakers" ? "menu-item active" : "menu-item"}>
                      Konuşmacılar
                    </h3>
                  </Link>
                </Col>
                <Col span={4} className="menu-item-col" >
                  <Link to="/experience">
                    <h3 className={location.pathname == "/experience" ? "menu-item active" : "menu-item"}>
                      Deneyim
                    </h3>
                  </Link>
                </Col>
                <Col span={4} className="menu-item-col" >
                  <Link to="/contact">
                    <h3 className={location.pathname == "/contact" ? "menu-item active" : "menu-item"}>
                      İletişim
                    </h3>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col md={5} xl={5} sm={0} xs={0} className="padding2 alignR">
              <Link to="/">
                <img src={require("../assets/images/logo-mini.svg").default} className="main-logo-mini" alt='main-logo-mini' />
              </Link>
            </Col>
            <Col md={0} xl={0} sm={12} xs={12} className="padding2 alignR">
              <Dropdown overlay={menu}>
                <MenuOutlined style={{ color: "white", fontSize: "24px", fontFamily: "SFBold", marginRight: "2%", cursor: "pointer" }} />
              </Dropdown>

            </Col>
          </Row>

        </Header>

        <Content className='content'>
          <div
            className={`${transitionStage}`}
            onAnimationEnd={() => {
              if (transitionStage === "fadeOut") {
                setTransistionStage("fadeIn");
                setDisplayLocation(location);
              }
            }}
          >

            <Routes>
              <Route path="/" exact element={<Main />} >

              </Route>
              <Route path="aboutus" element={<Aboutus />} />
              <Route path="experience" element={<Experience />} />
              <Route path="speakers" element={<Speakers />} />
              <Route path="contact" element={<Contact />} />
              <Route path="program" element={<Program />}>

              </Route>
              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>There's nothing here!</p>
                  </main>
                }
              />

            </Routes>

          </div>
        </Content>

        <Footer>
          <Row>
            <Col span={5} className="alignL">
              <Space>
                <img src={require("../assets/images/twitter.svg").default}
                  className="social-logo"
                  alt='social-logo-twitter'
                  onClick={() => { window.open("https://twitter.com/UABakanligi", '_blank'); }}
                />
                <img src={require("../assets/images/facebook.svg").default}
                  className="social-logo"
                  alt='social-logo-facebook'
                  onClick={() => { window.open("https://www.facebook.com/UABakanligi/", '_blank'); }}
                />
                <img src={require("../assets/images/instagram.svg").default}
                  className="social-logo"
                  alt='social-logo-instagram'
                  onClick={() => { window.open("https://www.instagram.com/uabakanligi/", '_blank'); }} />
              </Space>
            </Col>
            <Col sm={19} xs={19} md={14} xl={14} className="alignC">
              <Space>
                <div className='footer-date'>
                  1 - 2  TEMMUZ 2022
                </div>
                <div className='footer-location'>
                  | TERSANE İSTANBUL
                </div>
              </Space>
            </Col>
            <Col sm={0} xs={0} md={5} xl={5} className="alignL">
              <div className='footer-rigth'>
                ©️ 2022 T.C. Ulaştırma ve Altyapı Bakanlığı Tüm hakları saklıdır.
              </div>
            </Col>
          </Row>
        </Footer>
      </Layout>

    </div>
  );
}