
export const SpeakerList = [
    {
        "name": "Recep Tayyip ERDOĞAN",
        "title": "Türkiye Cumhurbaşkanı",
        "image": require('./speakers_images/RTE-X2.jpg'),
        "reis":true
    },
    {
        "name": "Adil Karaismailoğlu",
        "title": "T.C. Ulaştırma ve Altyapı Bakanı",
        "image": require('./speakers_images/ADIL-K.jpg')
    }, {
        "name": "Oramiral Adnan ÖZBAL",
        "title": "Deniz Kuvvetleri Komutanı",
        "visible": false,
        "image": require('./speakers_images/ORAMIRAL-ADNAN-OZBAL.jpg')
    }, {
        "name": "Selim Dursun",
        "title": "T.C. Ulaştırma ve Altyapı Bakan Yardımcısı",
        "image": require('./speakers_images/SELIM-DURSUN.jpg')
    }, {
        "name": "Mehmet Emin BİRPINAR",
        "title": "T.C. Çevre, Şehircilik ve İklim Değişikliği Bakan Yardımcısı",
        "image": require('./speakers_images/MEHMET-EMIN-BIRPINAR.jpg')
    }, {
        "name": "Yavuz Selim KIRAN",
        "title": "T.C. Dışişleri Bakan Yardımcısı",
        "image": require('./speakers_images/YAVUZ-SELIM-KIRAN.jpg')
    }, {
        "name": "Dr. Alparslan BAYRAKTAR",
        "title": "T.C. Enerji ve Tabii Kaynaklar Bakan Yardımcısı",
        "image": require('./speakers_images/ALPARSLAN-BAYRAKTAR.jpg')
    }, {
        "name": "Mehmet Fatih KACIR",
        "title": "T.C. Sanayi ve Teknoloji Bakan Yardımcısı",
        "image": require('./speakers_images/MEHMET-FATIH-KACIR.jpg')
    }, {
        "name": "Prof. Dr. İsmail DEMİR",
        "visible": false,
        "title": "T.C. Cumhurbaşkanlığı Savunma Sanayii Başkanı",
        "image": require('./speakers_images/ISMAIL-DEMIR.jpg')
    }, {
        "name": "Kitack LIM",
        "title": "IMO Genel Sekreteri",
        "image": require('./speakers_images/KITACK-LIM.jpg')
    }, {
        "name": "Cleopatra Doumbia – HENRY",
        "title": "Dünya Denizcilik Üniversitesi Başkanı",
        "image": require('./speakers_images/CLEOPATRA-DOUMBIA.jpg')
    }, {
        "name": "Mark SZAKONYI",
        "title": "IHS Markit Editörü",
        "image": require('./speakers_images/MARK-SZAKONYI.jpg')
    },
    {
        "name": "Ali İbrahim KONTAYTEKİN",
        "title": "TAİS Başkanı",
        "image": require('./speakers_images/ALI-IBRAHIM-KONTAYTEKIN.jpg')
    }
    , {
        "name": "Aydın ERDEMİR",
        "title": "TURKLİM Başkanı",
        "image": require('./speakers_images/AYDIN-ERDEMIR.jpg')
    }
    , {
        "name": "Birol ÜNER",
        "title": "GEMAK CEO",
        "image": require('./speakers_images/BIROL-UNER.jpg')
       
    }
    , {
        "name": "Durmuş ÜNÜVAR",
        "title": "Kıyı Emniyeti Genel Müdürü",
        "image": require('./speakers_images/DURMUS-UNUVAR.jpg')
    }
    , {
        "name": "Dr. Özkan POYRAZ",
        "title": "Emekli Müsteşar",
        "image": require('./speakers_images/OZKAN-POYRAZ.jpg')
    }
    , {
        "name": "Dr. Yalçın EYİGÜN",
        "title": "AYGM Genel Müdürü",
        "image": require('./speakers_images/YALCIN-EYIGUN.jpg')
    }
    , {
        "name": "İrfan METE",
        "title": "Türkiye Denizciler Sendikası Başkanı",
        "image": require('./speakers_images/IRFAN-METE.jpg')
    }
    , {
        "name": "İsmail ERTÜZÜN",
        "title": "Emeklilik Hizmetleri Genel Müdürü",
        "image": require('./speakers_images/ISMAIL-ERTUZUN.jpg')
    }
    , {
        "name": "Kâmil ÖZKAN",
        "title": "Kurumlar Vergisi Daire Başkanı",
        "image": require('./speakers_images/KAMIL-OZKAN.jpg')
    }
    , {
        "name": "Mehmet S. GÜNGEN",
        "title": "Güngen Denizcilik Yönetim Kurulu Başkanı",
        "image": require('./speakers_images/MEHMET-SAIM-GUNGEN.jpg')
    }
    , {
        "name": "Nurcan ÖNDER",
        "title": "Çalışma Genel Müdürü",
        "image": require('./speakers_images/NURCAN-ONDER.jpg')
    }
    , {
        "name": "Osman Nurettin PAKSU",
        "title": "TERSAN Yönetim Kurulu Başkanı",
        "image": require('./speakers_images/OSMAN-NURETTIN-PAKSU.jpg')
    }
    , {
        "name": "Prof. Dr. Ersan BAŞAR",
        "title": "Karadeniz Teknik Üniversitesi, Akademisyen",
        "image": require('./speakers_images/ERSAN-BASAR.jpg')
    }
    , {
        "name": "Prof. Dr. Oral ERDOĞAN",
        "title": "Piri Reis Üniversitesi Rektörü",
        "image": require('./speakers_images/ORAL-ERDOGAN.jpg')
    }
    , {
        "name": "Prof. Dr. Soner ESMER",
        "title": "Dokuz Eylül Üniversitesi, Akademisyen",
        "image": require('./speakers_images/SONER-ESMER.jpg')
    }
    , {
        "name": "Salih TAN",
        "title": "Tersaneler ve Kıyı Yapıları Genel Müdürü",
        "image": require('./speakers_images/SALIH-TAN.jpg')
    }
    , {
        "name": "S.Zeki ÇAKIR",
        "title": "İMEAK DTO Meclis Başkanı",
        "image": require('./speakers_images/SALIH-ZEKI-CAKIR.jpg')
    }
    , {
        "name": "Şadan KAPTANOĞLU",
        "title": "TURMEPA Başkanı, İMEAK DTO Başkan Yardımcısı",
        "image": require('./speakers_images/SADAN-KAPTANOGLU.jpg')
    }
    , {
        "name": "Tamer KIRAN",
        "title": "Deniz Ticaret Odası Yönetim Kurulu Başkanı",
        "image": require('./speakers_images/TAMER-KIRAN.jpg')
    }
    , {
        "name": "Ümit Recep UĞUR",
        "title": "Denizbank Genel Müdür Yardımcısı",
        "image": require('./speakers_images/UMIT-RECEP-UGUR.jpg')
    }
    , {
        "name": "Ünal BAYLAN",
        "title": "Denizcilik Genel Müdürü",
        "image": require('./speakers_images/UNAL-BAYLAN.jpg')
    }
    , {
        "name": "Yavuz ALBAYRAK",
        "title": "BDDK Uygulama İl Daire Başkanı",
        "image": require('./speakers_images/YAVUZ-ALBAYRAK.jpg')
    }
    , {
        "name": "Yüksel YILDIRIM",
        "title": "Yıldırım Şirketler Grubu İcra Kurulu Başkanı",
        "image": require('./speakers_images/YUKSEL-YILDIRIM.jpg')
    }
   
]